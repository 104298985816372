import { Dispatch, PropsWithChildren, SetStateAction, createContext, useCallback, useContext, useEffect, useMemo, useState } from 'react';
import { useGetVersion } from 'src/use-cases/invoice/useInvoices';
import { InvoiceSummaryDto } from 'src/generated/services/TFinancialApi';

interface InvoiceContextProps {
  invoiceSummary: InvoiceSummaryDto;
  version?: string;
}
export interface IInvoiceContext {
  invoiceSummary: InvoiceSummaryDto;
  invoiceId: string;
  version?: string;
  tabDetails: number;
  isShowErrorVersion: boolean;
  setIsShowErrorVersion: Dispatch<SetStateAction<boolean>>;
  setVersion: Dispatch<SetStateAction<string>>;
  checkVersion: (successCb: () => void, failedCb?: () => void) => void;
  setTabDetails: (index: number) => void;
}
export const InvoiceContext = createContext<IInvoiceContext>(null);

export const InvoiceContextProvider = ({ invoiceSummary, version, children }: PropsWithChildren<InvoiceContextProps>) => {
  const invoiceId = invoiceSummary?.id;
  const [isShowErrorVersion, setIsShowErrorVersion] = useState<boolean>(false);
  const [currentVersion, setCurrentVersion] = useState<string>(version);
  const [tabDetails, setTabDetails] = useState<number>(0);
  const { refetch: refetchVersion, data, isRefetching } = useGetVersion(invoiceId);

  useEffect(() => {
    setCurrentVersion(data);
  }, [data, isRefetching]);

  /**
   * checkVersion
   */
  const checkVersion = useCallback(
    async (successCb: () => void, failedCb?: () => void) => {
      const respVersion = await refetchVersion();

      if (version && respVersion?.data && version !== respVersion?.data) {
        setIsShowErrorVersion(true);
        if (failedCb) {
          failedCb();
        }
      } else {
        successCb();
      }
    },
    [version, setIsShowErrorVersion, refetchVersion]
  );

  const invoiceContextProps = useMemo(
    () => ({
      invoiceId,
      invoiceSummary,
      version: currentVersion,
      tabDetails,
      setVersion: setCurrentVersion,
      isShowErrorVersion,
      setIsShowErrorVersion,
      checkVersion,
      setTabDetails,
    }),
    [invoiceId, invoiceSummary, currentVersion, tabDetails, isShowErrorVersion, checkVersion]
  );
  return <InvoiceContext.Provider value={invoiceContextProps}>{children}</InvoiceContext.Provider>;
};

// eslint-disable-next-line react-refresh/only-export-components
export const useInvoiceContext = () => {
  const ctx = useContext(InvoiceContext);
  if (ctx) return ctx;
  throw new Error('Component has to use inside of InvoiceContext');
};
